export default {
  main_manager: [
    {
      action: ["list"],
      subject: ["Board"]
    },
    {
      action: ["create", "delete", "edit"],
      subject: ["Board:column"]
    },
    {
      action: ["create", "delete", "edit", "changeManager"],
      subject: ["Board:card"]
    }
  ],
  admin: [
    {
      action: ["list"],
      subject: ["Board"]
    },
    {
      action: ["create", "delete", "edit"],
      subject: ["Board:column"]
    },
    {
      action: ["create", "delete", "edit", "changeManager"],
      subject: ["Board:card"]
    }
  ],
  manager: []
};
